import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-10 ml-10" }
const _hoisted_2 = { class: "mr-10 ml-10 mb-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Editor = _resolveComponent("Editor")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
        onTabClick: _ctx.handleClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "基本信息",
            name: "first"
          }),
          _createVNode(_component_el_tab_pane, {
            label: "扩展信息",
            name: "second"
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onTabClick"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_form, {
        ref: "params",
        model: _ctx.params,
        rules: _ctx.paramsRules,
        "label-width": "84px",
        class: ""
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_el_form_item, { label: "文章栏目" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_cascader, {
                  props: _ctx.categoryProps,
                  "show-all-levels": false,
                  modelValue: _ctx.categorySelected,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.categorySelected) = $event)),
                  options: _ctx.category,
                  onChange: _ctx.handleChange
                }, null, 8, ["props", "modelValue", "options", "onChange"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "文章标题",
              prop: "title"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.title,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.title) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "文章内容" }, {
              default: _withCtx(() => [
                _createVNode(_component_Toolbar, {
                  style: {"width":"100%","border-bottom":"1px solid #eee"},
                  editor: _ctx.editorRef,
                  defaultConfig: _ctx.toolbarConfig,
                  mode: _ctx.mode
                }, null, 8, ["editor", "defaultConfig", "mode"]),
                _createVNode(_component_Editor, {
                  style: {"width":"100%","height":"500px","overflow-y":"hidden","border":"1px solid #eee"},
                  modelValue: _ctx.params.content,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.params.content) = $event)),
                  defaultConfig: _ctx.editorConfig,
                  mode: _ctx.mode,
                  onOnCreated: _ctx.handleCreated
                }, null, 8, ["modelValue", "defaultConfig", "mode", "onOnCreated"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "内容功能" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_checkbox, {
                  modelValue: _ctx.autoImg,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.autoImg) = $event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" 提取第 "),
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.picNum,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.picNum) = $event)),
                      class: "w-80 mr-8 ml-8",
                      placeholder: "请输入内容"
                    }, null, 8, ["modelValue"]),
                    _createTextVNode("张图片作封面 ")
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_checkbox, {
                  modelValue: _ctx.autoDes,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.autoDes) = $event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("提取文章描述")
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "发布时间" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.params.createdAt,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.params.createdAt) = $event)),
                  "default-value": new Date(),
                  type: "datetime",
                  placeholder: "选择日期时间"
                }, null, 8, ["modelValue", "default-value"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "是否显示" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, {
                  modelValue: _ctx.params.status,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.params.status) = $event)),
                  label: "1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("发布")
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_radio, {
                  modelValue: _ctx.params.status,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.params.status) = $event)),
                  label: "2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("不发布")
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "浏览数" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.pv,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.params.pv) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, _ctx.activeIndex == 0]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_el_form_item, { label: "SEO标题" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.seo_title,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.params.seo_title) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "SEO关键词" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.seo_keywords,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.params.seo_keywords) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "SEO描述" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.seo_description,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.params.seo_description) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "来源" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.source,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.params.source) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "作者" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.author,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.params.author) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, _ctx.activeIndex == 1]
          ]),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.submit('params')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("保存")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ])
  ], 64))
}